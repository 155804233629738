
//#region ======== imports
import ProctoringService from "@/services/ProctoringService";
import { computed, defineComponent, onMounted, PropType, reactive, ref, watch, watchEffect } from "vue";
import { useRouter } from "vue-router";
import Icon from "@/components/Icon.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";
import AlertList from "@/components/audit/AlertList.vue";
import moment from "moment";
import AlertListDTO from "@/dtos/AlertListDTO";
import vm from "@/viewModels/MainViewModel";
import { maskCpf } from '@/utils/stringFunctions';
import { useAlert } from '@/hooks/alert';
import Loading from "@/components/Loading.vue";
import { trackers } from "@/hooks/trackers";
import ExamStateDTO from "@/dtos/ExamStateDTO";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import ProctoringResponseDTO from "@/dtos/ProctoringResponseDTO";
import TextJustificationDTO from "@/dtos/TextJustificationDTO";
import { CandidateDTO } from "@/dtos/CandidateDTO";
import UpdateAlertDTO from "@/dtos/UpdateAlertDTO";
import { formatDate, formatTime } from "@/utils/meus-exames/datetime";
import AuditScreenEnum from "@/enums/AuditScreenEnum";
import { swapElementClasses } from "@/utils/swapElementClasses";
//#endregion

const AuditPage = defineComponent({
    components: {
        Icon,
        AlertList,
        VideoPlayer,
        Loading,
    },
    emits: [
        'updateAudit',
        'backPage',
        'nextPage',
        'startAudit',
        'statusConfirmScheduling',
        'statusDeniedScheduling',
        'statusConfirmAudit',
        'toggleNextPageEnabled',
        'alertsTotalUpdated',
        'alertsAuditedUpdated',
        'statusConfirmQualifyAudit',
        'statusDenyQualifyAudit'
    ],
    props: {
        candidate: {
            type: Object as PropType<CandidateDTO>,
            required: true,
        },
        exam: {
            type: Object as PropType<ExamResponseDTO>,
            required: true,
        },
        audit: {
            type: Object as PropType<ProctoringResponseDTO>,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        justificationText: {
            type: Object as PropType<TextJustificationDTO>,
            required: true,
        },
        hasQuestionsPhotos: {
            type: Boolean,
            default: true
        },
        activeScreen: {
            type: Object as PropType<AuditScreenEnum>,
            required: true,
        }
    },
    setup(props, context) {
        const router = useRouter();
        const alert = useAlert();
        const state = reactive<ExamStateDTO>({
            twilioToken: null,
            sid: null,
            participants: 0,
            loading: false,
            loadingResponses: false,
            userName: "",
            userCPF: "",
            proctoringType: "",
            status: "",
            alerts: [],
            startTime: "",
            endTime: "",
            timeExam: 0,
            sendDate: "",
            videoUrl: "",
            screenUrl: "",
            id: "",
            key: 0,
            examId: "",
            errorPage: false,
            examAnswer: null,
            exam: null,
            totalPages: 1,
            page: 1,
            pageSessions: 1,
            itemsPage: 3,
            alertsPage: [],
            sessions: [],
            totalPagesAlerts: 1,
            onlyAlertsPage: [],
            showedAlertsPage: [],
            imageAlerts: [],
            totalObjectives: 0,
            objectivesCorrect: 0,
            desclassificationModal: false,
            isDisqualifyAudit: false,
            viewerStatus: "",
            viewerComment: "",
            saving: false,
            faceImageURL: "",
            justificationText: null,
            auditor: "",
            audit: null,
            examinador: "",
            countAlertImg: 0,
            countNoAlertImg: 0,
            selectedImages: null,
            alertsPhotosEnviroment: [],
            cameraAlerts: props.audit?.alerts ?? []
        });

        const isViewerCoordRole = vm.user?.roleDescription == "VIEWER" || vm.user?.roleDescription == "COORD";
        const isMasterRole = vm.user?.roleDescription == 'MASTER';

        const activeBtn = ref<string>(state.proctoringType);
        activeBtn.value = "VIDEO";
        const formatStatus = (status: string) => {
            switch (status) {
                case "WAITING_AUDITING":
                    return "Aguardando auditoria";
                case "IN_ANALYSIS":
                    return "Em análise";
                case "WAITING_CONFIRMATION":
                    return "Aguardando confirmação do examinador";
                case "DISQUALIFIED":
                    return "Desclassificado";
                case "RELEASED":
                    return "Liberado";
                default:
                    return "Não auditado";
            }
        };

        const userRoleDescription = computed(() => {
            return vm.user?.roleDescription;
        });

        const disableAudit = (exam: ExamResponseDTO): boolean => {
            if (userRoleDescription.value === 'SUPPORT' ||
                userRoleDescription.value === 'COORD' ||
                userRoleDescription.value === 'VIEWER' ||
                (isMasterRole && (exam.resultStatus === 'RELEASED' || exam.resultStatus == 'DISQUALIFIED'))) {
                return true;
            }
            if ((userRoleDescription.value == 'ADMIN' || isMasterRole) && exam.resultStatus === 'WAITING_CONFIRMATION') {
                return true;
            }
            return false;
        }

        const alertasFiltrados = computed(() => {
            const alerts: Array<AlertListDTO> = [];

            for (let i = 0; i < state.alerts.length; i++) {
                if (state.alerts[i].type.toUpperCase() === activeBtn.value) {
                    alerts.push(state.alerts[i]);
                }
                if (activeBtn.value === 'VIDEO') {
                    if (state.alerts[i].type.toUpperCase() === 'AUDIO') {
                        alerts.push(state.alerts[i]);
                    }
                }
            }
            const finalAlerts = JSON.parse(JSON.stringify(alerts));
            return finalAlerts;
        });

        const loadAudit = async () => {
            state.startTime = formatTime(props.audit.examStartTime);
            state.endTime = formatTime(props.audit.examEndTime);
            state.timeExam = moment(new Date(props.audit.examEndTime)).diff(new Date(props.audit.examStartTime), 'minutes');
            state.faceImageURL = props.audit.faceImageURL;
            state.timeExam = moment(new Date(props.audit.examEndTime)).diff(new Date(props.audit.examStartTime), 'minutes');
            state.justificationText = props.justificationText;

            trackers.registerLoadAudit(state.auditor, "Carregada auditoria");
        };

        const loadImg = () => {
            props.audit.imageAlerts.map(x => {
                const url = x.url.replace(`https://${process.env.VUE_APP_BLOB_EXAME}.blob.core.windows.net/images/`, '');
                x.url = `https://${process.env.VUE_APP_BLOB_EXAME}.blob.core.windows.net/images/${url}`
            });
            state.imageAlerts = props.audit.imageAlerts;

            state.selectedImages = { index: '0', url: state.imageAlerts.length > 0 ? state.imageAlerts[0].url : '' };
            const filterAlertsPhotosEnviroments = props.audit.imageAlerts.filter(x => x.alertas.length > 0);

            // zerando alertas de imagens sempre que fizer um loading
            state.alertsPhotosEnviroment = [];
            state.countAlertImg = 0;
            let index = 1;
            filterAlertsPhotosEnviroments.map((x): void => {
                x.alertas.map(x => {
                    x.photoName = `Foto ${index++}`;
                    state.alertsPhotosEnviroment.push(x);
                });
            });
            state.countNoAlertImg = filterAlertsPhotosEnviroments.filter(x => x.resultFrameId == '').length;
            props.audit.imageAlerts.map(x => {
                if (x.alertas.length > 0) {
                    state.countAlertImg++;
                }
            });

            state.countAlertImg -= state.countNoAlertImg;
        }

        const updateAlertStatus = (targetArray: AlertListDTO[], alerts: UpdateAlertDTO[]) => {
            targetArray
                .map(x => {
                    const index = alerts.findIndex(e => e.id == x.id);
                    x.alertStatus = index >= 0 ? alerts[index].alertStatus : x.alertStatus;
                });
        };

        const statusConfirmScheduling = () => {
            context.emit('statusConfirmScheduling')
        };

        const statusConfirmAudit = async () => {
            context.emit('statusConfirmAudit', props.exam.proctoringId)
        };

        const statusConfirmQualifyAudit = async () => {
            context.emit('statusConfirmQualifyAudit', props.exam.proctoringId)
        };

        const confirmStatusDeniedScheduling = () => {
            state.desclassificationModal = false;
            context.emit('statusDeniedScheduling', state.viewerComment)
        };

        const confirmStatusDenyQualifyAudit = () => {
            state.desclassificationModal = false;
            context.emit('statusDenyQualifyAudit', state.viewerComment)
        };

        watchEffect(() => {
            const alerts: AlertListDTO[] = props.activeScreen === AuditScreenEnum.ENVIRONMENT
                ? state.alertsPhotosEnviroment
                : state.cameraAlerts;

            const alertsAudited = alerts.filter(
                x =>
                    !!x['alertStatus'] && x.alertStatus !== 'REVIEWING' &&
                    !!x['category'] && x.category !== 'NoAlert'
            ).length;

            const alertsTotal = props.activeScreen === AuditScreenEnum.ENVIRONMENT
                ? state.countAlertImg
                : state.cameraAlerts.length;

            context.emit('alertsAuditedUpdated', alertsAudited);
            context.emit('alertsTotalUpdated', alertsTotal);
        });

        const toggleEvaluation = (
            type: 'acceptCandidate' | 'finalizeAudit' | 'qualifyAudit' | 'disqualify' | 'disqualifyAudit',
            isViewerCoord: boolean
        ) => {
            state.isDisqualifyAudit = (type == 'disqualifyAudit');

            if (type == 'disqualify' || type == 'disqualifyAudit') {
                state.desclassificationModal = true;
                return;
            }

            let message = 'Tem certeza que deseja liberar o candidato?';
            let action = isViewerCoord || isMasterRole ? statusConfirmScheduling : statusConfirmAudit;

            if (type == 'qualifyAudit') {
                message = 'Tem certeza que deseja liberar e classificar o candidato?';
                action = statusConfirmQualifyAudit;
            }

            alert({
                title: 'Confirmar',
                message: message,
                actions: [
                    {
                        title: 'Cancelar',
                    },
                    {
                        title: 'Confirmar',
                        action: action
                    }
                ]
            });

        };

        const dropdown = ref();

        /** Redirect */
        const redirect = () => {
            const url = router.resolve({
                path: `/exam-report/${props.exam.id}`,
            });
            window.open(url.href, "_blank");
            trackers.registerPageView(state.auditor, `/exam-report/${props.exam.id}`);
        };

        const changePhoto = (type: 'next' | 'previous' | 'click', clickNumber?: number) => {
            const minIndex = 0; // Define your minimum index here
            const maxIndex = state.imageAlerts.length - 1; // Define your maximum index here

            if (type === 'next') {
                let nextIndex = parseInt(state.selectedImages!.index) + 1;
                if (nextIndex > maxIndex) {
                    nextIndex = maxIndex;
                }

                state.selectedImages!.index = nextIndex.toString();
                state.selectedImages!.url = state.imageAlerts[nextIndex].url;
            } else if (type === 'previous') {
                let previousIndex = parseInt(state.selectedImages!.index) - 1;
                if (previousIndex < minIndex) {
                    previousIndex = minIndex;
                }

                state.selectedImages!.index = previousIndex.toString();
                state.selectedImages!.url = state.imageAlerts[previousIndex].url;
            } else if (clickNumber != undefined) {
                state.selectedImages!.index = `${clickNumber}`;
                state.selectedImages!.url = state.imageAlerts[clickNumber].url;
            }
        };

        watch(() => props.loading, () => {
            state.loading = props.loading
        });




        watch(() => vm.hiddenMenu, (newValue: boolean) => swapElementClasses(newValue, 'leftBar','col-span-5', 'col-span-8' ));


        watch(() => props.audit?.alerts, () => {
            state.cameraAlerts = props.audit?.alerts ?? [];
        });

        const reprocessingAlerts = async () => {
            state.loading = true;
            try {
                const [reprocess] = ProctoringService.PostReprocessing(props.exam.proctoringId)
                await reprocess;
                alert({
                    title: "Sucesso!",
                    message: "Os alertas foram reprocessados",

                })
                context.emit('startAudit', props.candidate);
            } catch (error: any) {
                alert({
                    title: "Não foi possível reprocessar os alertas",
                    message: error
                })
            } finally {
                state.loading = false;
            }
        }

        const onEnvironmentAlertAudited = (updated: UpdateAlertDTO[]) => {
            updateAlertStatus(state.alertsPhotosEnviroment, updated);
        };
        const onCameraAlertAudited = (updated: UpdateAlertDTO[]) => {
            updateAlertStatus(state.cameraAlerts, updated);
        };

        onMounted(() => {
            window.scrollTo(0, 0);
            loadImg();
            swapElementClasses(vm.hiddenMenu, 'leftBar','col-span-5', 'col-span-8' );
        });

        return {
            state,
            redirect,
            formatStatus,
            alertasFiltrados,
            activeBtn,
            userRoleDescription,
            statusConfirmAudit,
            confirmStatusDeniedScheduling,
            maskCpf,
            dropdown,
            toggleEvaluation,
            changePhoto,
            reprocessingAlerts,
            vm,
            onEnvironmentAlertAudited,
            onCameraAlertAudited,
            formatDate,
            formatTime,
            moment,
            isViewerCoordRole,
            isMasterRole,
            disableAudit,
            confirmStatusDenyQualifyAudit,
            AuditScreenEnum
        };
    },
});

export default AuditPage;
