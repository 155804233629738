
import { defineComponent, reactive, onMounted, watch, ref, onBeforeUnmount } from "vue";
import UsuarioService from "@/services/UsuarioService";
import UserCard from "@/components/UserCard.vue";
import UserDTO from "@/dtos/UserDTO";
import { useAlert } from "@/hooks/alert";
import { Canceler } from 'axios';
import UserResponse from "@/dtos/UserResponse";
import RegisterStatusEnum from "@/enums/RegisterStatusEnum";
import SearchInputUser from "@/components/SearchInputUser.vue";
import { trackers } from "@/hooks/trackers";
import { testCpf, testEmail } from "@/utils/validationFunctions";
import { removeCpfMask, titleCase } from "@/utils/stringFunctions";

interface UsersViewState {
    loading: boolean;
    paginationLoading: boolean;
    users: UserDTO[];
    showInviteModal: boolean;
    page: number;
    limit: number;
    totalUsers: number;
    totalPages: number;
    totalItens: number;
    loadingToggle: boolean;
    qtd: number;
    error: boolean;
    errorMessage: string;
}

const ListUsersView = defineComponent({
    components: {
        UserCard,
        SearchInputUser
    },

    setup() {
        const alert = useAlert();
        const searchTerm = ref("");


        const state = reactive<UsersViewState>({
            loading: true,
            paginationLoading: true,
            users: [],
            showInviteModal: false,
            page: 1,
            limit: 15,
            totalUsers: 0,
            totalPages: 0,
            totalItens: 0,
            loadingToggle: false,
            qtd: 0,
            error: false,
            errorMessage: ''
        });


        const scrollContainer = ref<HTMLElement | null>(null);
        let requestCanceler: Canceler | null;


        const getUsers = async (limit: number, page: number) => {
            state.paginationLoading = true;
            requestCanceler && requestCanceler();

            const [request, canceler] = UsuarioService.getAllUsers(limit, page);

            requestCanceler = canceler;
            try {
                const response: UserResponse = await request;
                state.users = page > 1 ? [...state.users, ...response.content] : response.content;

                state.totalUsers = Number(response.itensCount);

                state.totalPages = response.pageCount;
            }
            catch (error: any) {
                alert({
                    message: "Não foi possivel buscar os usuários",
                    actions: [{ title: "Cancelar" }, { title: "Tentar novamente", action: () => getUsers(limit, page) }]
                })
            } finally {
                state.paginationLoading = false;
            }
        }



        const fetchData = async () => {
            try {
                state.loading = true;
                state.error = false;
                await getUsers(state.limit, state.page);
            }
            catch (error: any) {
                alert({
                    title: "Não foi possivel buscar os usuários",
                    message: error
                })
            }
            finally {
                state.loading = false;
            }
        };


        const enableDisableUser = async (id: string) => {
            try {
                if (state.loadingToggle) return;
                state.loadingToggle = true;
                const user = state.users.find(x => x.id === id);
                if (user) {
                    const enableDisable = user.registerStatus == RegisterStatusEnum.COMPLETED ? RegisterStatusEnum.DISABLED : RegisterStatusEnum.COMPLETED;
                    const [request, canceler] = await UsuarioService.enableDisableUser(id, enableDisable);
                    user.registerStatus = await request;
                }
            } catch (error: any) {
                console.log(error);
                alert({
                    title: "Não foi possivel habilitar/desabilitar o usuário",
                    message: error
                })
            } finally {
                state.loadingToggle = false;
            }

        };

        const searchUser = async (value: string | any | null) => {
            if (state.loading) return;

            state.loading = true;
            state.error = false;

            state.users = [];
            state.totalUsers = 0;

            if (value == '' || value == null || value == undefined || !value) {
                state.page = 1;
                await fetchData();
                return;
            }

            try {
                const isValid = testCpf(value) || testEmail(value);
                if (!isValid) {
                    state.error = true;
                    state.errorMessage = ("cpf ou e-mail inválido");
                    return;
                }

                if (testCpf(value)) {
                    value = removeCpfMask(value);
                }

                let request: Promise<UserDTO[]> | null = null;
                let canceler: Canceler | null = requestCanceler;
                let response: UserDTO[] = [];

                try {
                    [request, canceler] = UsuarioService.getUserCpfEmail(value);
                    response = [];
                    response = await request; // atribuindo à variável já declarada
                } catch (error: any) {
                    state.errorMessage = ("Usuário não encontrado");
                    state.error = true;
                    return;
                }

                state.users = response;
                state.totalUsers = response.length;
            } catch (error) {
                trackers.registerError('Erro ao buscar usuários', error!.toString());
                console.log(error);
            } finally {
                state.loading = false;
            }
        };


        watch(() => state.page, async (newPage, oldPage) => {
            if (newPage !== oldPage) {
                await getUsers(state.limit, state.page);
            }
        });

        const nextPage = () => {
            if (state.loading) {
                return;
            }

            if (state.page < state.totalPages && !state.paginationLoading) {
                state.page++;
                setTimeout(() => {
                    if (scrollContainer.value) {
                        scrollContainer.value.scrollTop -= 50;
                    }
                }, 200);
            }
        };

        const handleScroll = () => {
            if (!scrollContainer.value) return;
            const { scrollTop, scrollHeight, clientHeight } = scrollContainer.value;
            if (scrollTop + clientHeight >= scrollHeight - 50) {
                nextPage();
            }
        };


        onMounted(async () => {
            try {
                await fetchData();
                if (scrollContainer.value) {
                    scrollContainer.value.addEventListener('scroll', handleScroll);
                }
            }
            catch (e) {
                console.log(e);
            }
            finally {
                state.loading = false;
            }
        });

        onBeforeUnmount(() => {
            if (scrollContainer.value) {
                scrollContainer.value.removeEventListener('scroll', handleScroll);
            }
        });

        return {
            state,
            scrollContainer,
            nextPage,
            enableDisableUser,
            searchUser,
            searchTerm
        };
    }
});

export default ListUsersView;
