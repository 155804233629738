
import { computed, defineComponent, onMounted, reactive, PropType } from "vue";
import Icon from "@/components/Icon.vue";
import { format } from "date-fns";
import { maskCpf } from '@/utils/stringFunctions';
import vm from "@/viewModels/MainViewModel";
import { CandidateDTO } from "@/dtos/CandidateDTO";
import { getDocExam } from "@/repositories/FirestoreExams";
import AuditDTO from "@/dtos/AuditDTO";
import UserDTO from "@/dtos/UserDTO";
import ToggleSwitch from "./ToggleSwitch.vue";
import RegisterStatusEnum from "@/enums/RegisterStatusEnum";

const UserCard = defineComponent({
    components: {
        Icon,
        ToggleSwitch
    },
    props: {
        user: {
            type: Object as PropType<UserDTO>,
            required: true
        },
        onFocus: {
            type: Boolean,
            default: false
        },
    },
    setup(props, { emit }) {


        const enableDisableUser = () => {
            emit('enableDisableUser',props.user.id);
        }
        return {
            maskCpf,
            enableDisableUser,
            RegisterStatusEnum 
        };
    },
});

export default UserCard;
