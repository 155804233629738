
import { defineComponent, reactive, watch } from "vue";


//  Components
import { required, testCpf, testEmail, validEmailOrCpf } from "@/utils/validationFunctions";
import LoginInput from "@/components/LoginInput.vue";
import Button from "@/components/Button.vue";
import CreateUserIlustration from "@/components/illustrations/CreateUserIlustration.vue";
import UsuarioService from "@/services/UsuarioService";
import { useAlert } from "@/hooks/alert";
import vm, { FederativeUnit } from "@/viewModels/MainViewModel";
import Icon from "@/components/Icon.vue";
import { trackers } from "@/hooks/trackers";
import GestorService from "@/services/GestorService";

interface CreateUser {
    cpf: string,
    email: string,
    name: string,
    password: string,
    loading: false,
    error: string,
    federativeUnit: string,
    federativeUnitName: string,
    role: number,
    federativeUnits: FederativeUnit[],
    showDropDown: boolean,

}

const CreateusersView = defineComponent({
    components: { LoginInput, Button, CreateUserIlustration, Icon },
    setup() {
        const alert = useAlert();
        const state = reactive<CreateUser>({
            cpf: "",
            email: "",
            name: "",
            password: "",
            loading: false,
            error: "",
            federativeUnit: "",
            role: 0,
            federativeUnits: [],
            showDropDown: false,
            federativeUnitName: ""
        });

        state.federativeUnits = vm.federativeUnits;
        const submit = async () => {
            if (state.cpf != "" &&
                state.email != "" &&
                state.name != "" &&
                state.role != 0 &&
                state.federativeUnit != "") {

                //validar email e cpf
                if (!testCpf(state.cpf))
                    alert({
                        color: "red",
                        title: "Erro",
                        message: "CPF inválido!"
                    });

                else if (!testEmail(state.email))
                    alert({
                        color: "red",
                        title: "Erro",
                        message: "Email inválido!"
                    });

                else {
                    try {
                        UsuarioService.PostUser({
                            cpf: state.cpf.trim(),
                            email: state.email.trim(),
                            name: state.name.trim(),
                            role: state.role,
                            federativeUnit: state.federativeUnit,
                            password: ""
                        });

                        alert(
                            {
                                title: "Cadastro concluído!",
                                message: "Verifique seu email para iniciar seu primeiro acesso."
                            });
                        trackers.registerUserAdd(state.email, "O usuário já pode iniciar seu primeiro acesso.");
                        clearData();

                    } catch (error) {
                        alert(
                            {
                                color: "red",
                                title: "Erro",
                                message: error as string
                            });
                        trackers.registerError('Erro ao cadastrar usuário', error as string);
                    }
                }

            } else {
                alert({
                    color: "red",
                    title: "Erro",
                    message: "Será preciso preencher todos os campos"
                });
            }
        };
        const changeRole = (role: number) => {
            state.role = role;
        };
        const clearData = () => {
            state.cpf = "";
            state.email = "";
            state.federativeUnit = "";
            state.name = "";
            state.role = 0;
        };

        const changeUf = (uf: string, name: string) => {
            state.federativeUnit = uf;
            state.federativeUnitName = name;
            state.showDropDown = false;
        };

        watch(() => state.cpf, () => {
            state.showDropDown = false;
        });
        watch(() => state.email, () => {
            state.showDropDown = false;
        });
        watch(() => state.name, () => {
            state.showDropDown = false;
        });
        watch(() => state.role, () => {
            state.showDropDown = false;
        });
        watch(() => state.federativeUnit, () => {
            state.showDropDown = false;
        });

        return { required, validEmailOrCpf, state, submit, changeRole, changeUf };
    },
});

export default CreateusersView;
