
import { defineComponent, onMounted, onUnmounted, PropType, reactive, ref, watch } from "vue";
import { AlertaDTO, IAlert } from "@/dtos/AlertaDTO";
import AlertCard from "./AlertCard.vue";
import DropdownActionButton from "../DropdownActionButton.vue";
import Icon from "../Icon.vue";
import ProctoringService from "@/services/ProctoringService";
import Loading from "@/components/Loading.vue";
import { CurrentSituationStatusType } from "@/interfaces/CurrentSituationStatus.types";
import ExameService from "@/services/ExameService";
import { trackers } from "@/hooks/trackers";
import AuditDTO from "@/dtos/AuditDTO";
import { updateDocExam } from "@/repositories/FirestoreExams";
import vm from "@/viewModels/MainViewModel";
import { CandidateDTO } from "@/dtos/CandidateDTO";
import { useAlert } from "@/hooks/alert";
import UpdateAlertDTO from "@/dtos/UpdateAlertDTO";
import useSignalSender from "@/hooks/signalSender";
import AuditScreenEnum from "@/enums/AuditScreenEnum";

interface State {
    alerts_array: IAlert[][];
    order_array: IAlert[];
    checkedStatus: string;
    checked: boolean;
    filter?: string;
    loading: boolean;
    firstAlertAudited: boolean;
}

export const VALIDATE_ACTIONS_OPTIONS = [
    {
        label: "Liberar Alerta",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Alerta Inválido",
        value: "SYSTEM_FAILURE",
    },
];

const FILTER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Video",
        value: "Video",
    },
    {
        label: "Audio",
        value: "Audio",
    },
    {
        label: "Todos",
        value: "",
    },
];

const ORDER_OPTIONS = [
    {
        label: "Liberados",
        value: "RELEASED",
    },
    {
        label: "Indício de fraude",
        value: "BLOCKED",
    },
    {
        label: "Não auditados",
        value: "REVIEWING",
    },
    {
        label: "Inválidos",
        value: "SYSTEM_FAILURE",
    },
    {
        label: "Avisos",
        value: "WARNING",
    },
    {
        label: "Todos",
        value: "",
    },
];

const AlertList = defineComponent({
    components: {
        alertCard: AlertCard,
        DropdownActionButton,
        Icon,
        Loading,
    },
    emits: [
        'environmentAlertAudited', 
        'cameraAlertAudited'
    ],
    props: {
        candidate: {
            type: Object as PropType<CandidateDTO>,
            required: true,
        },
        alerts: Array as PropType<AlertaDTO[]>,
        proctoringId: {
            type: String,
            required: true,
        },
        overlay: Boolean,
        disableAudit: {
            type: Boolean,
            required: false,
            default: false,
        },
        examId: {
            type: String,
            required: true,
        },
        env: {
            type: Object as PropType<AuditScreenEnum>,
            default: AuditScreenEnum.NONE,
        },
        proctoringType: String
    },
    setup(props, { emit }) {
        const state = reactive<State>({
            alerts_array: [],
            order_array: [],
            checkedStatus: "NONE",
            checked: false,
            filter: "",
            loading: true,
            firstAlertAudited: false
        });


        const avaliarDropdown = ref();
        const filtrarDropdown = ref();
        const ordenarDropdown = ref();        
        const alert = useAlert();
        watch(() => props.alerts, () => verifyAlerts());

        const checkAllAlerts = () => {
            state.checked = !state.checked;
            state.checkedStatus = "ALL";
            toggleCheckAll();
        };

        const uncheckAllAlerts = () => {
            state.checked = false;
            state.checkedStatus = "NONE";
            toggleCheckAll(true);
        };

        const handleSelectFilter = (value: string) => {
            state.filter = value;
        };

        const handleSelectOrder = async (value: string) => {
            toggleCheckAll(true);

            let array = state.order_array;
            let tempFullArray: IAlert[] = [];

            array?.forEach(item => item.alertStatus === value ? tempFullArray.push({ ...item, checked: false }) : null);
            array?.forEach(item => item.alertStatus !== value ? tempFullArray.push({ ...item, checked: false }) : null);

            state.alerts_array = fetchAlerts(tempFullArray);
            state.order_array = tempFullArray;
        };

        const handleAudit = (audit: CurrentSituationStatusType, alertId = null) => {
            const alerts = alertId !== null
                ? state.order_array.flat(1).filter((alert) => alert.id === alertId)
                : state.order_array.flat(1).filter((alert) => alert.checked);
            const data = alerts.map((alert) => {
                return { alertId: alert.id, alertStatus: audit };
            });

            if(data.length === 0) {
                return;
            }
            
            const isEnvironment = props.env == AuditScreenEnum.ENVIRONMENT;
            const auditBasedOnScreen = (isEnvironment) ? ProctoringService.AuditImage : ProctoringService.Audit;

            const [request] = auditBasedOnScreen(props.proctoringId, { audit: data, token: vm.userProctoring!.token });
            state.loading = true;
            request
                .then(() => {
                    alerts.forEach((alert) => {
                        alert.alertStatus = audit;
                    });

                    const updates = mapUpdateAlertsToDto(alerts);
                    const event = isEnvironment ? 'environmentAlertAudited' : 'cameraAlertAudited';
                    emit(event, updates);

                    uncheckAllAlerts();

                    if (!state.firstAlertAudited && !!props.examId) {
                        const audit: AuditDTO = {
                            schedulingId: props.candidate.schedulingId,
                            statusAudit: "auditing",
                            auditorCpf: vm.user?.cpf,
                            auditorName: vm.user?.name
                        }
                        updateDocExam(audit);
                        
                        const [request] = ExameService.UpdateStatus(props.examId, 'IN_ANALYSIS');
                        request
                            .finally(() => {
                                state.firstAlertAudited = true;
                            });
                    }
                })
                .catch((error) => {
                    trackers.registerError(props.examId, `Erro: ${error.toString()}`);
                    alert({
                        title: "Aviso",
                        message: error
                    });
                })
                .finally(() => {
                    state.loading = false;
                });
        };

        const mapUpdateAlertsToDto = (alerts: IAlert[]): UpdateAlertDTO[] => {
            return alerts.map(x => {
                const dto: UpdateAlertDTO = {
                    id: x.id,
                    alertStatus: x.alertStatus
                };
                return dto;
            });
        };

        const toggleCheck = (id: number) => {
            state.order_array.forEach((alert) => {
                if (alert.id === id) {
                    alert.checked = !alert.checked;
                }
            });
        };

        const toggleCheckAll = (forceUncheck?: boolean) => {
            state.order_array.forEach((alert) => {
                if (forceUncheck) {
                    alert.checked = false;
                } else {
                    if (alert.category !== 'NoAlert' && (
                        state.filter === alert.type || 
                        state.filter === alert.alertStatus || 
                        state.filter === '')) 
                    {
                        alert.checked = state.checked;
                    }
                }
            });
        };

        const fetchAlerts = (alerts: IAlert[]): IAlert[][] => {
            const alerts_array: IAlert[][] = [];
            let temp_array;
            for (let i = 0; i < alerts.length;) {
                temp_array = [];
                for (let x = i; ; x++) {
                    if (alerts[x] && alerts[x].category === alerts[i].category) {
                        temp_array.push(alerts[x]);
                    } else {
                        i = x;
                        break;
                    }
                }
                alerts_array.push(temp_array);
            }
            return alerts_array;
        };

        const arrayIsNoEmpty = (array: any[]) => {
            return array.length > 0 ? true : false;
        };

        const verifyAlerts = () => {
            if (props.alerts) {
                state.checked = false;
                state.loading = true;
                const alerts = props.alerts.map((alert) => {
                    return { ...alert, checked: false };
                });
                state.alerts_array = fetchAlerts(alerts);
                state.order_array = alerts;
                state.loading = false;
            }
        };

        onMounted(() => {
            verifyAlerts();
            window.addEventListener('resize', onAlertListScrolledOrResized);
        });

        onUnmounted(() => {
            window.removeEventListener('resize', onAlertListScrolledOrResized);
        });

        const closeModal = (event: any) => {
            if (event.path != undefined) {
                if (!event.path[0].className.includes('dropdown') && (avaliarDropdown.value || filtrarDropdown.value || ordenarDropdown.value)) {
                    avaliarDropdown.value.closeModal();
                    filtrarDropdown.value.closeModal();
                    ordenarDropdown.value.closeModal();
                }
            }
        };

        const scrollOrResizeEvent ="AlertListScrolledOrResizedEvent";
        const scrollEventSender = useSignalSender(scrollOrResizeEvent);
        const onAlertListScrolledOrResized = () => scrollEventSender.send();

        return {
            FILTER_OPTIONS,
            ORDER_OPTIONS,
            VALIDATE_ACTIONS_OPTIONS,
            checkAllAlerts,
            handleAudit,
            handleSelectFilter,
            handleSelectOrder,
            state,
            toggleCheck,
            closeModal,
            avaliarDropdown,
            filtrarDropdown,
            ordenarDropdown,
            arrayIsNoEmpty, 
            onAlertListScrolledOrResized, 
            scrollOrResizeEvent
        };
    },
});

export default AlertList;
