
import {
    defineComponent,
    ref,
    reactive,
    onMounted,
    computed,
    WritableComputedRef,
    PropType,
    onBeforeUnmount,
} from "vue";
import Icon from "@/components/Icon.vue";
import moment from "moment";
import AlertList from "./audit/AlertList.vue";
import { AlertaDTO } from "@/dtos/AlertaDTO";

const VideoPlayer = defineComponent({
    components: {
        Icon,
        alertList: AlertList,
    },
    props: {
        src: {
            type: String,
            required: true,
        },
        alerts: {
            type: Array as PropType<AlertaDTO[]>,
            required: true,
        },
        id: {
            type: String,
            required: true,
        }
    },
    setup(props) {
        const auditVideo = ref<HTMLMediaElement | null>(null);
        const myPlayer = ref<HTMLDivElement | null>(null);
        const progress = ref<HTMLDivElement | null>(null);

        const state = reactive({
            currentDuration: 0,
            totalDuration: 0,
            percentage: 0,
            isFullScreen: false,
            alertListOpen: true,
            playing: false,
            muted: false,
            id: '', 
            isVideoLoadingError: false,
        });
  
        const currentDurationFormatted: WritableComputedRef<string> = computed(
            () => {
                return moment.utc(state.currentDuration * 1000).format("mm:ss");
            }
        );

        const totalDurationFormatted: WritableComputedRef<string> = computed(() => {
            return moment.utc(state.totalDuration * 1000).format("mm:ss");
        });

        const play = () => {
            state.playing ? auditVideo.value?.pause() : auditVideo.value?.play();
            state.playing = !state.playing;
        };
        const stop = () => {
            if (!auditVideo.value) return null;
            auditVideo.value.currentTime = 0;
            auditVideo.value.pause();
            state.playing = false;
        };
        const mute = () => {
            state.muted = !state.muted;
        };

        const fullScreen = () => {
            var targetelement = myPlayer.value;

            if (targetelement?.requestFullscreen) {
                targetelement.requestFullscreen();
            }
        };

        const exitfullScreen = () => {
            document.exitFullscreen();
        };

        const loadVideo = () => {
            if (auditVideo.value) {
                auditVideo.value.currentTime = 7 * 24 * 60 * 1000;
                auditVideo.value.onseeked = () => {
                    if (auditVideo.value) {
                        state.totalDuration = auditVideo.value?.duration || 0;
                        auditVideo.value.currentTime = 0;
                        auditVideo.value.onseeked = null;

                        auditVideo.value?.addEventListener("timeupdate", (event) => {
                            state.currentDuration = auditVideo.value?.currentTime || 0;
                            state.percentage = Math.ceil(
                                (state.currentDuration / state.totalDuration) * 100
                            );
                        });
                        progress.value?.addEventListener("click", (e) => {
                            if (progress.value && auditVideo.value) {
                                const progressTime =
                                    (e.offsetX / progress.value?.offsetWidth) *
                                    state.totalDuration;
                                auditVideo.value.currentTime = progressTime;
                            }
                        });
                    }
                };
            }

            document.onfullscreenchange = () => {
                state.isFullScreen = !!document.fullscreenElement;
            };
        };

        const toogleAlertList = () => {
            state.alertListOpen = !state.alertListOpen;
        };

        onMounted(() => {
            loadVideo();
            stop();
            
            const video = auditVideo.value as HTMLVideoElement;
            video?.addEventListener("error", (e) => {
                state.isVideoLoadingError = true;
            });
        });

        onBeforeUnmount(()=>{
            if(auditVideo.value)
            {
                auditVideo.value.pause();
                auditVideo.value.src = "";
            }
        });

        onBeforeUnmount(()=>{
            if(auditVideo.value)
            {
                auditVideo.value.pause();
                auditVideo.value.src = "";
            }
        });

        return {
            state,
            auditVideo,
            progress,
            currentDurationFormatted,
            totalDurationFormatted,
            myPlayer,
            play,
            stop,
            mute,
            fullScreen,
            toogleAlertList,
            exitfullScreen,
        };
    },
});

export default VideoPlayer;
