
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'ToggleSwitch',
    props: {
        active: {
            type: Boolean,
            required: true,
            default: true
        }, 
        disabled: {
            type: Boolean,
            default: false
        }
    },

    setup(props, { emit }) {
        
        const inputCheckbox = computed({
            get() {
                return props.active;
            },
            set(value: any) {
                emit('update:active', value);
            }
        });

        return { inputCheckbox }
    }
})
