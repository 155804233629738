
import { defineComponent, PropType, reactive, watchEffect, ref, watch, onMounted } from "vue";
import { maskCpf } from "@/utils/stringFunctions";
import BaseAvatar from "../BaseAvatar.vue";
import moment from "moment";
import router from "@/router";
import { trackers } from "@/hooks/trackers";
import vm from "@/viewModels/MainViewModel";
import Icon from "../Icon.vue";
import ExamResponseDTO from "@/dtos/ExamResponseDTO";
import AuditScreenEnum from "@/enums/AuditScreenEnum";
import { UserRoleEnum } from "@/enums/UserRoleEnum";
import { HeaderParams } from "@/interfaces/HeaderParams";
import { FederativeUnitEnum } from "@/enums/FederativeUnitEnum";
import Loading from "@/components/Loading.vue";
import GestorExamDTO from "@/dtos/gestor/GestorExamDTO";
import { useAlert } from '@/hooks/alert';
import ExameService from "@/services/ExameService";
import { swapElementClasses } from "@/utils/swapElementClasses";
import { useRouter } from "vue-router";

const alert = useAlert();

interface Header {
    timeExam: number;
    loading: boolean;
    screenDescription: string;
    userRole: UserRoleEnum;
    currentStep: number;
    availableSteps: number;
}

const HeaderAudit = defineComponent({
    emits: ['changeToScreen', 'confirmAudit'],
    components: { avatar: BaseAvatar, Icon, Loading },
    props: {
        exam: {
            type: Object as PropType<ExamResponseDTO>,
            required: true,
        },
        faceImageURL: {
            type: String,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        activeScreen: {
            type: Object as PropType<AuditScreenEnum>,
            required: true,
        },
        isBackPageEnabled: {
            type: Boolean,
            required: true,
        },
        isNextPageEnabled: {
            type: Boolean,
            required: true,
        },
        alertsAuditedCount: {
            type: Number,
            required: true
        },
        alertsTotalCount: {
            type: Number,
            required: true
        },
        hideAuditingCount: {
            type: Boolean,
            required: false,
            default: false
        },
        hideStepInfo: {
            type: Boolean,
            required: false,
            default: false
        },
        onlyAlertsTotal: {
            type: Boolean,
            required: false,
            default: false
        },
        headerParams: {
            type: Object as PropType<HeaderParams>,
        }
    },
    setup(props, { emit }) {
        const state = reactive<Header>({
            timeExam: 0,
            loading: false,
            screenDescription: '',
            userRole: UserRoleEnum.NONE,
            currentStep: 0,
            availableSteps: 0
        });
        const { replace } = useRouter();

        const printingCertificate = ref<boolean>(false);
        const printingExam = ref<GestorExamDTO>();

        const formatStatus = (status: string): string => {
            const statusMap: Record<string, string> = {
                "NONE": "Em execução",
                "WAITING_AUDITING": "Aguardando auditoria",
                "IN_ANALYSIS": "Em análise",
                "WAITING_CONFIRMATION": "Aguardando confirmação",
                "DISQUALIFIED": "Desclassificado",
                "RELEASED": "Liberado",
            };

            return statusMap[status] || "Não auditado";
        };

        const certificateGivenAtChange = async (exam: GestorExamDTO, certificateGivenAt: string) => {
            try {
                await ExameService.UpdateCertificateGivenAt(exam.id, certificateGivenAt)[0];
                exam.certificateGivenAt = certificateGivenAt;
            }
            catch (_error) {
                alert({ message: "Erro ao atualizar essa informação no banco de dados. Tente novamente mais tarde" });
            }
        };

        const confirm = (exam: GestorExamDTO) => {
            alert({
                title: "Confirmação",
                message: "O certificado foi entregue para o candidato ?",
                actions: [
                    {
                        title: "Não",
                        action: async () => {
                            replace({ name: 'finished-exam-view' });
                        }
                    },
                    {
                        title: "Sim",
                        primary: true,
                        action: async () => {
                            if (exam) {
                                await certificateGivenAtChange(exam, new Date().toISOString());
                                replace({ name: 'finished-exam-view' });
                            }
                        }
                    }
                ]
            });
        };

        const onMessage = (event: any) => {
            window.removeEventListener('message', onMessage);
            const message = event.data;
            if (message.type === 'onLoad') {
                printingCertificate.value = false;
                if (printingExam.value && !printingExam.value.certificateGivenAt)
                    confirm(printingExam.value);
            }
            else if (message.type === 'onLoadError') {
                printingCertificate.value = false;
                alert({
                    title: "Erro",
                    message: `O certificado não pode ser impresso: ${message.content}`
                });
            }
        };

        const printCertificate = async () => {
            printingCertificate.value = true;
            printingExam.value = await ExameService.GetExameGestorBySchedulingId(props.exam.schedulingId)[0];
            window.addEventListener('message', onMessage);

            localStorage.setItem("certificate", JSON.stringify(printingExam.value));
            window.open('/certificate', 'iframePrint');
        };

        const formatDate = (date: string) => {
            return moment(date).format("DD/MM/YYYY");
        };
        const formatTime = (time: string) => {
            return moment.utc(time).format("HH:mm:ss");
        };

        const redirect = () => {
            const url = router.resolve({
                path: `/exam-report/${props.exam.id}`,
            });
            window.open(url.href, "_blank");
            trackers.registerPageView(props.exam.auditor, `/exam-report/${props.exam.id}`);
        };

        const ufName = () => vm.ufName(props.exam.federativeUnit);

        const screenDescriptions: string[] = [];
        screenDescriptions[AuditScreenEnum.QUESTIONS] = "Questões";
        screenDescriptions[AuditScreenEnum.CAMERA] = "Câmera";
        screenDescriptions[AuditScreenEnum.ENVIRONMENT] = "Ambiente";
        screenDescriptions[AuditScreenEnum.STREAM] = "Streaming";
        screenDescriptions[AuditScreenEnum.AUTOMATIC] = "Auditoria Automática";
        state.screenDescription = screenDescriptions[props.activeScreen];

        watchEffect(() => {
            state.screenDescription = screenDescriptions[props.activeScreen];

            const hasQuestionsStep = props.headerParams?.hasQuestionsPhotos && props.headerParams?.userRole != UserRoleEnum.ADMIN || props.exam.federativeUnit == "CE";
            const hasCameraStep = props.headerParams?.candidateUf != FederativeUnitEnum.CE;
            const hasEnvironmentStep = props.headerParams?.hasEnvironmentAlerts;

            state.availableSteps =
                (hasQuestionsStep ? 1 : 0) +
                (hasCameraStep ? 1 : 0) +
                (hasEnvironmentStep ? 1 : 0);

            state.currentStep = props.activeScreen;

            //Steam de video e auditoria automatica só tem uma página.
            if (props.activeScreen == AuditScreenEnum.STREAM || props.activeScreen == AuditScreenEnum.AUTOMATIC) {
                state.currentStep = 1;

            } else if (props.activeScreen > 1 && !hasQuestionsStep) {
                state.currentStep--;
            }
        });

        watch(() => vm.hiddenMenu, (newValue: boolean) => {
            swapElementClasses(newValue, 'headerLeft', 'col-span-5', 'col-span-8')
        });

        onMounted(() => swapElementClasses(vm.hiddenMenu, 'headerLeft', 'col-span-5', 'col-span-8'))

        const defaultTheme = ref<boolean>(false);
        const theme = localStorage.getItem('theme') ?? 'default-theme';
        defaultTheme.value = theme == 'default-theme';

        const changeToScreen = (screen: AuditScreenEnum) => {
            emit('changeToScreen', screen);
        };

        const printGabarito = () => {
            const url = `/certificate/${props.exam.schedulingId}`;
            window.open(url, '_blank');
        }


        return {
            moment,
            formatStatus,
            formatDate,
            formatTime,
            maskCpf,
            redirect,
            vm,
            state,
            ufName,
            changeToScreen,
            printCertificate,
            printingCertificate,
            defaultTheme,
            printGabarito
        };
    },
});

export default HeaderAudit;
