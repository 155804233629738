
import Icon from '@/components/Icon.vue';
import AlertList from '@/components/audit/AlertList.vue';
import AlertListDTO from '@/dtos/AlertListDTO';
import { CandidateDTO } from '@/dtos/CandidateDTO';
import ExamResponseDTO from '@/dtos/ExamResponseDTO';
import ExamStateDTO from '@/dtos/ExamStateDTO';
import TextJustificationDTO from '@/dtos/TextJustificationDTO';
import { useAlert } from '@/hooks/alert';
import { trackers } from '@/hooks/trackers';
import router from '@/router';
import ExameService from '@/services/ExameService';
import ProctoringService from '@/services/ProctoringService';
import TwilioService from '@/services/TwilioService';
import { activeBtnToAuditScreenEnum } from '@/utils/alertsUtils';
import { swapElementClasses } from '@/utils/swapElementClasses';
import vm from "@/viewModels/MainViewModel";
import moment from 'moment';
import * as Video from 'twilio-video';
import { PropType, computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch, watchEffect } from 'vue';


const MyAutomaticAudit = defineComponent({
    components: { Icon, AlertList },
    emits: [
        'statusDeniedScheduling',
        'updateAlerts'
    ],
    props: {
        examID: {
            type: String,
            required: true
        },
        candidate: {
            type: Object as PropType<CandidateDTO>,
            required: true,
        },
        exam: {
            type: Object as PropType<ExamResponseDTO>,
            required: true,
        },
        loading: {
            type: Boolean,
            required: false,
        },
        justificationText: {
            type: Object as PropType<TextJustificationDTO>,
            required: true,
        }
    },
    setup(props, context) {
        const alert = useAlert();

        const loadingStream = ref(true);

        const state = reactive<ExamStateDTO>({
            twilioToken: null,
            sid: null,
            participants: 0,
            loading: false,
            loadingResponses: false,
            userName: "",
            userCPF: "",
            proctoringType: "REALTIME",
            status: "",
            alerts: [],
            startTime: "",
            endTime: "",
            timeExam: 0,
            sendDate: "",
            videoUrl: "",
            screenUrl: "",
            id: "",
            key: 0,
            examId: "",
            errorPage: false,
            examAnswer: null,
            exam: null,
            totalPages: 1,
            page: 1,
            pageSessions: 1,
            itemsPage: 3,
            alertsPage: [],
            sessions: [],
            totalPagesAlerts: 1,
            onlyAlertsPage: [],
            showedAlertsPage: [],
            imageAlerts: [],
            totalObjectives: 0,
            objectivesCorrect: 0,
            desclassificationModal: false,
            viewerStatus: "",
            viewerComment: "",
            saving: false,
            faceImageURL: "",
            justificationText: null,
            auditor: "",
            audit: null,
            examinador: "",
            countAlertImg: 0,
            countNoAlertImg: 0,
            selectedImages: null,
            alertsPhotosEnviroment: [],
            cameraAlerts: []
        });

        let interval = 0;
        const activeBtn = ref<string>(state.proctoringType);

        onMounted(async () => {
            await getToken();//twilio
            interval = setInterval(loadAudit, 10000);//atualiza auditoria de 30 em 30s
        });

        onBeforeUnmount(() => {
            const twillioConnected = localStorage.getItem('twillioConnected');
            if (twillioConnected) {
                disconnectAll();
            }
            clearInterval(interval);
        });

        const getToken = async () => {
            const [request] = await TwilioService.GetStreams(vm.user?.name.split(' ')[0] as string || "admin", props.examID);
            request
                .then(async (resp) => {
                    state.twilioToken = resp;
                    await startVideo();
                });
        };

        const sendOk = () => {
            const [request] = TwilioService.PostStreams(props.examID);
            request.catch((error: any) => {
                if (error == 'Existe um usuário conectado ao stream de video') {
                    setTimeout(() => {
                        if (loadingStream.value == true) {
                            alert({
                                title: "Sala ocupada!",
                                message: "Por favor, tente novamente mais tarde.",
                                actions: [
                                    {
                                        title: "Ok",
                                        primary: true,
                                    }
                                ],
                            });
                        }
                    }, 2000)
                }
            });
        };

        const leaveVideo = () => {
            const [request] = TwilioService.PostLeaveStreams(props.examID);
            request;
        };

        const alertLeaveVideo = () => {
            alert({
                title: "Desconectar vídeo",
                message: "Deseja realmente se desconectar da exibição do vídeo?",
                actions: [
                    {
                        title: "Cancelar",
                        primary: true,
                    },
                    {
                        title: "Desconectar",
                        action: () => {
                            disconnectAll();
                        },
                    },
                ],
            });
        }

        let twilioRoom: Video.Room;

        const startVideo = async () => {
            if (state.twilioToken) {
                await Video.connect(state.twilioToken, { name: props.examID, audio: false, video: false }).then(room => {
                    state.sid = room.sid;
                    twilioRoom = room;

                    trackers.registerStreamLog(room.sid, "connected", { message: `Connected to Room ${room.name}` });
                    localStorage.setItem('twillioConnected', room.sid);
                    setInterval(() => {
                        if (state.participants < room.participants.size) {
                            state.participants = room.participants.size;
                            room.participants.forEach((item) => addParticipant(item));
                        } else if (state.participants > room.participants.size) {
                            disconnectAll();

                            trackers.registerStreamLog(room.sid, "stoped", { message: `Disconnected to Room ${room.name}` })

                            router.replace({ name: 'exames' });
                        }
                        state.participants = room.participants.size;
                    }, 1000);
                });

            }
        };

        sendOk();

        const disconnectAll = () => {
            twilioRoom.disconnect();

            twilioRoom.localParticipant.audioTracks.forEach((item) => {
                item.track.detach();
            });
            twilioRoom.localParticipant.videoTracks.forEach((item) => {
                item.track.detach();
            });
            trackers.registerStreamLog(state.sid, "stoped", { message: `Sala ${twilioRoom.name}, camera e audio desconectada!` })
            localStorage.removeItem('twillioConnected');
            // Exit on API
            leaveVideo();
        };


        const addParticipant = function (participant: any) {
            trackers.registerStreamLog(participant.identity, "connected", { message: `Participant added` })
            const chat = document.querySelector("#remote");
            const element = document.createElement("div");
            element?.classList.add("w-full");
            element?.classList.add("flex");
            element?.classList.add("justify-center");
            const trackSubscribed = (track: { attach: () => any; }) => {
                element.appendChild(track.attach());
            };
            participant.tracks.forEach((trackPublication: { track: any; }) => {
                if (trackPublication.track) {
                    trackSubscribed(trackPublication.track);
                }
            });
            participant.on("trackSubscribed", trackSubscribed);
            chat!.appendChild(element);

            loadingStream.value = false;
        };


        const formatStatus = (status: string) => {
            switch (status) {
                case "WAITING_AUDITING":
                    return "Aguardando auditoria";
                case "IN_ANALYSIS":
                    return "Em análise";
                case "WAITING_CONFIRMATION":
                    return "Aguardando confirmação do examinador";
                case "DISQUALIFIED":
                    return "Desclassificado";
                case "RELEASED":
                    return "Liberado";
                default:
                    return "Não auditado";
            }
        };

        const formatTime = (time: string) => {
            return moment.utc(time).format("HH:mm:ss");
        };

        const formatDate = (date: string) => {
            return moment(date).format("DD/MM/YYYY");
        };

        const confirmStatusDeniedScheduling = () => {
            state.desclassificationModal = false;
            context.emit('statusDeniedScheduling', state.viewerComment)
        }
        const userRoleDescription = computed(() => {
            return vm.user?.roleDescription;
        });

        const alertasFiltrados = computed(() => {
            const alerts: Array<AlertListDTO> = [];

            for (let i = 0; i < state.alerts.length; i++) {
                if (state.alerts[i].type.toUpperCase() === activeBtn.value) {
                    alerts.push(state.alerts[i]);
                }
                if (activeBtn.value === 'VIDEO') {
                    if (state.alerts[i].type.toUpperCase() === 'AUDIO') {
                        alerts.push(state.alerts[i]);
                    }
                }
            }
            const finalAlerts = JSON.parse(JSON.stringify(alerts));
            return finalAlerts;
        });


        const loadAudit = async () => {
            console.log("loadAudit", state.audit?.status)
            if (state.audit == undefined || state.audit?.status == "STARTED") {
                const [request] = ProctoringService.GetProctoring(props.exam.proctoringId);
                state.audit = await request;
                console.log("loadAudit", state.audit)
                state.startTime = formatTime(state.audit.examStartTime);
                state.endTime = formatTime(state.audit.examEndTime);
                state.timeExam = moment(new Date(state.audit.examEndTime)).diff(new Date(state.audit.examStartTime), 'minutes');
                state.faceImageURL = state.audit.faceImageURL;
                state.timeExam = moment(new Date(state.audit.examEndTime)).diff(new Date(state.audit.examStartTime), 'minutes');
                state.justificationText = props.justificationText;
                state.imageAlerts = state.audit.imageAlerts;

                state.selectedImages = { index: '0', url: state.imageAlerts.length > 0 ? state.imageAlerts[0].url : '' };

                const filterAlertsPhotosEnviroments = state.audit.imageAlerts.filter(x => x.alertas.length > 0);

                // zerando alertas de imagens sempre que fizer um loading
                state.alertsPhotosEnviroment = [];
                state.countAlertImg = 0;
                filterAlertsPhotosEnviroments.map(x => {
                    x.alertas.map(x => {
                        state.alertsPhotosEnviroment.push(x);
                    });
                });
                state.audit.imageAlerts.map(x => {
                    if (x.alertas.length > 0) {
                        state.countAlertImg++;
                    }
                });
                console.log("emitupdateAlerts", state.audit.alerts.length)
                context.emit('updateAlerts', state.audit.alerts.length);
                trackers.registerLoadAudit(state.auditor, "Carregada auditoria");
            }

        };

        const dropdown = ref();

        /** Redirect */
        const redirect = () => {
            const url = router.resolve({
                path: `/exam-report/${props.examID}`,
            });
            window.open(url.href, "_blank");
            trackers.registerPageView(state.auditor, `/exam-report/${props.examID}`);
        };

        const changePhoto = (type: 'next' | 'previous' | 'click', clickNumber?: number) => {
            const minIndex = 0; // Define your minimum index here
            const maxIndex = state.imageAlerts.length - 1; // Define your maximum index here

            if (type === 'next') {
                let nextIndex = parseInt(state.selectedImages!.index) + 1;
                if (nextIndex > maxIndex) {
                    nextIndex = minIndex; // Wrap around to the first image when reaching the end
                }

                state.selectedImages!.index = nextIndex.toString();
                state.selectedImages!.url = state.imageAlerts[nextIndex].url;
            } else if (type === 'previous') {
                let previousIndex = parseInt(state.selectedImages!.index) - 1;
                if (previousIndex < minIndex) {
                    previousIndex = maxIndex; // Wrap around to the last image when reaching the beginning
                }

                state.selectedImages!.index = previousIndex.toString();
                state.selectedImages!.url = state.imageAlerts[previousIndex].url;
            } else if (clickNumber != undefined) {
                state.selectedImages!.index = `${clickNumber}`;
                state.selectedImages!.url = state.imageAlerts[clickNumber - 1].url;
            }
        };




        watch(() => vm.hiddenMenu, (newValue: boolean) => swapElementClasses(newValue, 'leftBarAutomatic', 'col-span-7', 'col-span-8'));

        onMounted(() => swapElementClasses(vm.hiddenMenu, 'leftBarAutomatic', 'col-span-7', 'col-span-8'))

        watch(() => state.audit, () => {
            if (state.audit != null && state.audit?.status == "FINISHED") {
                alert({
                    message: "O candidato finalizou o exame, os dados da auditoria estarão salvos em breve...",
                    actions: [
                        {
                            title: "OK",
                            action: () => window.location.reload()
                        }
                    ]
                })
            }
        })

        return {
            loadingStream, alertLeaveVideo, leaveVideo, disconnectAll,
            state,
            redirect,
            formatStatus,
            alertasFiltrados,
            activeBtn,
            userRoleDescription,
            confirmStatusDeniedScheduling,
            dropdown,
            changePhoto,
            vm, 
            activeBtnToAuditScreenEnum
        };
    }
});

export default MyAutomaticAudit;
