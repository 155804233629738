
import { computed, defineComponent } from "vue";
import Icon from "@/components/Icon.vue";
import { maska } from "maska";

const SearchInput = defineComponent({
    directives: { maska },
    components: { Icon },
    props: {
        modelValue: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        mask: {
            type: String,
            default: ''
        }
    },
    emits: ['search', 'update:modelValue'],
    setup(props, context) {

        const inputValue = computed({
            get() {
                return props.modelValue;
            },
            set(value: string) {
                context.emit("update:modelValue", value);
            }
        });

        const isCPF = (value: string): boolean => {
            return /^[0-9]{3}\.[0-9]{3}\.[0-9]{3}-[0-9]{2}$/i.test(value);
        };

        const debounce = (func: (value: string) => void, delay: number) => {
            let timer: number;
            return function (this: void, ...args: [string]) {
                clearTimeout(timer);
                timer = setTimeout(() => {
                    func.apply(this, args);
                }, delay);
            };
        };

        const debouncedSearchUser = debounce((value: string) => {
            context.emit('search', value);
        }, 200); // 300ms de atraso

        const onChangeValue = (event: Event) => {
            const value = (event.target as HTMLInputElement).value;
            inputValue.value = value;
            debouncedSearchUser(value);
        };

        const clearInput = () => {
            inputValue.value = '';
            context.emit('search', null);
        };

        return { inputValue, onChangeValue, clearInput, isCPF };
    }
});

export default SearchInput;
